import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import { documentContentUploadSenti } from "../../api/Postaction";
import "./index.css";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegFileAlt } from "react-icons/fa";

function SentimentAnalysis() {
  const {
    setSentiTableHeading,
    setIsfilePathSenti,
    setSentiMedPulse,
    setSentiStoredFileName,
  } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [selectedFileSenti, setSelectedFileSenti] = useState(null);
  const [sentiData, setSentiData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sentiRecords, setSentiRecords] = useState([]);
  const [searchQuerySenti, setSearchQuerySenti] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleBrowseFilesClick = () => {
    const file_upload = document.getElementById("file_upload");
    file_upload.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFileSenti(file);
    setSearchQuerySenti("");
  };

  useEffect(() => {
    if (selectedFileSenti) {
      setIsLoading(true);
      const uploadFile = async () => {
        try {
          const result = await documentContentUploadSenti(selectedFileSenti);
          setSentiStoredFileName(result?.file_name);
          setSentiData(result?.df_data);
          setSentiTableHeading(result?.column_list);
          let fileData = {
            recent_file_path: result?.recent_file_path,
            file_name: result?.file_name,
          };
          setIsfilePathSenti(fileData);
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setIsLoading(false);
        }
      };

      uploadFile();
    }
  }, [selectedFileSenti]);

  const filterData = () => {
    if (searchQuerySenti.trim() === "") {
      return sentiData;
    }
    return sentiData?.filter((item) => {
      return Object.values(item).some((val) =>
        val.toLowerCase().includes(searchQuerySenti.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterData();
    const start = limit * (page - 1);
    const end = start + limit;
    const slicedData = filteredData?.slice(start, end);
    setSentiRecords(slicedData);
  }, [sentiData, page, limit, searchQuerySenti]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  if (sentiRecords?.length > 0) {
    setSentiMedPulse(true);
  }

  const handleUploadNew = () => {
    setSentiData([]);
    setSelectedFileSenti(null);
  };

  return (
    <div
      className="contentScroll"
      style={{ height: "78vh", display: "flex", flexDirection: "column" }}
    >
      <div className="container-fluid" style={{ flex: 1 }}>
        <div className="d-flex">
          <div className="col-12">
            <div className="row">
              <div className="col-12">
                {sentiData?.length > 0 ? null : (
                  <div
                    style={{
                      border: "2px dashed #d3d3d3",
                      borderRadius: "8px",
                      padding: "40px",
                      textAlign: "center",
                      maxWidth: "600px",
                      margin: "auto",
                      color: "#333",
                    }}
                  >
                    <p
                      style={{
                        marginBottom: "10px",
                        fontSize: "20px",
                        fontWeight: "500",
                      }}
                    >
                      Upload Document
                    </p>

                    <div
                      style={{
                        fontSize: "30px",
                        color: "#1a73e8",
                        marginBottom: "10px",
                      }}
                    >
                      <MdOutlineFileUpload />
                    </div>
                    <div
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <span
                        htmlFor="file_upload"
                        style={{
                          color: "#1a73e8",
                          cursor: "pointer",
                        }}
                        onClick={handleBrowseFilesClick}
                      >
                        Click to Upload
                      </span>
                      <input
                        id="file_upload"
                        type="file"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                        accept=".xlsx,.csv"
                      />
                      <span style={{ color: "#888" }}>or drag and drop</span>
                    </div>
                    <p style={{ color: "#a3a3a3", fontSize: "12px" }}>
                      Limit 200MB per file. XLSX, CSV
                    </p>

                    {selectedFileSenti && (
                      <div style={{ marginTop: "10px", color: "#555" }}>
                        <strong>Selected File:</strong> {selectedFileSenti.name}
                      </div>
                    )}
                  </div>
                )}

                {isLoading ? (
                  <CommonLoader />
                ) : (
                  sentiRecords?.length > 0 && (
                    <>
                      <div style={{ margin: "20px", gap: "5px" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>
                          <FaRegFileAlt />
                          Users Management
                        </span>
                        <span
                          style={{
                            color: "#1a73e8",
                            cursor: "pointer",
                            fontSize: "12px",
                            marginLeft: "5px",
                          }}
                          onClick={handleUploadNew}
                        >
                          Change/upload new
                        </span>
                      </div>
                      <Table
                        className="custom-table"
                        virtualized
                        wordWrap="break-word"
                        hover={false}
                        height={window.innerHeight - 150}
                        data={sentiRecords}
                        style={{
                          scrollbarWidth: "0",
                          maxHeight: "70vh",
                          width: "100%",
                        }}
                        bordered
                        rowKey={(rowData) => rowData.id}
                      >
                        {[...Object.keys(sentiRecords[0])]?.map(
                          (key, index) => (
                            <Column
                              flexGrow={
                                key.toLowerCase() === "description" ? 4 : 2
                              }
                              verticalAlign="middle"
                              key={key}
                            >
                              <HeaderCell>{key}</HeaderCell>
                              <Cell dataKey={key}>
                                {(rowData) => {
                                  const description = rowData[key] || "";
                                  const words =
                                    typeof description === "string"
                                      ? description.split(" ")
                                      : [];
                                  const displayText =
                                    words?.length > 4
                                      ? words?.slice(0, 4).join(" ") + "..."
                                      : description;

                                  return (
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        maxWidth: "400px",
                                      }}
                                      title={description}
                                    >
                                      {displayText}
                                    </div>
                                  );
                                }}
                              </Cell>
                            </Column>
                          )
                        )}
                      </Table>
                      <div style={{ paddingTop: 20 }}>
                        <Pagination
                          prev
                          next
                          first
                          last
                          ellipsis
                          boundaryLinks
                          maxButtons={5}
                          size="xs"
                          layout={["total", "-", "pager", "skip"]}
                          total={sentiData?.length}
                          limitOptions={[10, 30, 50]}
                          limit={limit}
                          activePage={page}
                          onChangePage={handlePageChange}
                          onChangeLimit={handleLimitChange}
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentimentAnalysis;