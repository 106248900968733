import React, { useState, useEffect, useContext } from "react";
import "./index.css";
// import { toast } from "react-toastify";
import {
  dataListInMedInsightSenti,
  getDropDownMedInsightsValue,
  dropdownMedInsightsUniqueValueData,
} from "../../api/Postaction";
import Plot from "react-plotly.js";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { TagPicker } from "rsuite";
import { FaRegSave } from "react-icons/fa";
import Select from "react-select";
import MessageParser from "../chatbot/MessageParser";
import ActionProvider from "../chatbot/ActionProvider";
import config from "../chatbot/config";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { medInsightsBot } from "../../utils/images";

function MedInsightsSenti() {
  const { sentiStoredFileNameMedpulse } = useContext(MyContext);
  const [drugOptionsSenti, setDrugOptionsSenti] = useState([]);
  const [loading, setLoading] = useState(false);

  const [dropdownData, setDropdownData] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [dropDownValue_2, setDropDownValue_2] = useState();
  const [selectedValues, setSelectedValues] = useState({});

  const [uniqueDropDownValue, setUniqueDropDownValue] = useState();

  const [botVisible, setBotVisible] = useState(true);

  const [nonSelectedDropDownValue, setNonSelectedDropDownValue] = useState([]);

  useEffect(() => {
    if (dropDownValue_2) {
      const response = async () => {
        let body = {
          column: dropDownValue_2?.value,
          stored_file_name_sen_tagged: sentiStoredFileNameMedpulse,
        };
        try {
          const dropDownValues = await getDropDownMedInsightsValue(body);

          const transformedData = Object.entries(
            dropDownValues?.columns
          ).flatMap(([category, items]) =>
            items?.map((item) => ({
              label: item,
              value: item,
              groupBy: category,
            }))
          );
          const uniqueGroupBy = [...new Set(transformedData.map(item => item.groupBy))];
          setNonSelectedDropDownValue(uniqueGroupBy);
          setDropdownData(transformedData);
        } catch (err) {
          console.log(err);
        }
      };
      response();
    }
  }, [dropDownValue_2]);

  const handleOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  const handleDropDownValue = (selectedItems) => {
    const groupedValues = selectedItems?.reduce((acc, item) => {
      const group = dropdownData?.find((d) => d.value === item)?.groupBy;

      if (group) {
        acc[group] = acc[group] ? [...acc[group], item] : [item];
      }
      return acc;
    }, {});

    setSelectedValues(groupedValues);
  };

  useEffect(() => {
    const response = async () => {
      let body = {
        stored_file_name_sen_tagged: sentiStoredFileNameMedpulse,
      };
      try {
        const res = await dropdownMedInsightsUniqueValueData(body);
        setUniqueDropDownValue(res);
      } catch (err) {
        console.log(err);
      }
    };

    response();
  }, []);

  const data = uniqueDropDownValue?.map((item) => ({
    label: item,
    value: item,
  }));

  const handleDropDownValuetwo = (option) => {
    setDropDownValue_2(option);
  };

  const handleAnalyzeClick = () => {
    const graphFunctionCall = async () => {
      let body = {
        stored_file_name_sen_tagged: sentiStoredFileNameMedpulse,
        filter_data: selectedValues,
        column_1: dropDownValue_2?.value,
        visualize_column: selectedValues.length> 0
          ? Object.keys(selectedValues)
          : nonSelectedDropDownValue,
      };
      setLoading(true);
      try {
        const response = await dataListInMedInsightSenti(body);
        setDrugOptionsSenti(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    graphFunctionCall();
  };

  const handleBotClicked = () => [setBotVisible(!botVisible)];

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Med Insights</h5>
                  <p className="content-lingo">
                    Med Insights transforms complex medical data into insightful
                    visualizations for enhanced understanding and
                    decision-making
                  </p>
                  <div className="row">
                    <label className="medInsightLabel">
                      Select the appropriate field to filter
                    </label>
                  </div>
                  <div className="row" style={{ alignItems: "center" }}>
                    <div className="col-4 custom-dropdown">
                      <Select
                        value={dropDownValue_2}
                        onChange={handleDropDownValuetwo}
                        options={data}
                        placeholder="Select a Column"
                        isClearable
                      />
                    </div>
                    <div className="col-4">
                      <TagPicker
                        data={dropdownData}
                        groupBy="groupBy"
                        labelKey="label"
                        valueKey="value"
                        placeholder="Choose the Filter"
                        style={{ width: 300 }}
                        open={isDropdownOpen}
                        onOpen={handleOpen}
                        onClose={handleClose}
                        onChange={(i) => {
                          handleDropDownValue(i);
                        }}
                      />
                    </div>
                    <div
                      className="col-2 saveButton-medInsights"
                      onClick={handleAnalyzeClick}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20px",
                        width: "120px",
                        flexGrow: 0,
                        flexShrink: 0,
                      }}
                    >
                      <span>Analyze</span>
                      <FaRegSave
                        color="black"
                        size={18}
                        style={{
                          marginLeft: "5px",
                          fontWeight: "bold",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {loading ? (
                  <div className="w-100 mt-5">
                    <CommonLoader />
                  </div>
                ) : (
                  <>
                    {drugOptionsSenti &&
                      drugOptionsSenti?.length > 0 &&
                      drugOptionsSenti?.map((e, i) => (
                        <div key={i} className="plot-container">
                          <Plot
                            data={e.data}
                            layout={{ ...e.layout, width: "900" }}
                            config={{
                              ...e.config,
                              displaylogo: false,
                              modeBarButtonsToRemove: ["lasso2d", "select2d"],
                            }}
                          />
                        </div>
                      ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chatbot-container">
        {!botVisible && (
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        )}
      </div>
      <div style={{ textAlign: "right" }}>
        <img
          onClick={handleBotClicked}
          src={medInsightsBot}
          alt="bot"
          className="botIcon"
        />
      </div>
    </>
  );
}

export default MedInsightsSenti;
