// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.lableInputError
{
    color: red;
    height: 10px;
    margin-top: 4px;
}`, "",{"version":3,"sources":["webpack://./src/common/labelWithInput/styles.css"],"names":[],"mappings":";;AAEA;;IAEI,UAAU;IACV,YAAY;IACZ,eAAe;AACnB","sourcesContent":["\n\n.lableInputError\n{\n    color: red;\n    height: 10px;\n    margin-top: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
