import React from "react";
import { commonStr } from "../../utils/constants/commonStrings";
import { groupUserIcon } from "../../utils/images";
import "./styles.scss";

function SidebarManagerItem({ onClick }) {
  return (
    <div className="sidebar-manger mt-2 px-2 py-3 iconHover" onClick={onClick}>
      <div className="sidebar-manger__imgwrap">
        <img src={groupUserIcon} alt="sidebar" />
        &ensp;
        <p className="font500">{commonStr.manageUsers}</p>
      </div>
    </div>
  );
}

export default SidebarManagerItem;
