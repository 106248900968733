// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mask-box {
  border-radius: 20px;
  position: relative;
  background: white;
  margin-left: 15px;
  border: 1px solid #D8D8D8;
}
.mask-box .mask {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background: #4C85F7;
  position: absolute;
  transition: all 0.2s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 100px;
  height: 40px;
  font-weight: bold;
  transition: all 0.2s 0.1s ease;
  text-transform: none !important;
  white-space: nowrap;
  font-size: 15px;
}
.mask-box .MuiButton-root:hover {
  border-radius: 18px;
  background-color: transparent !important; 
}
`, "",{"version":3,"sources":["webpack://./src/common/Toggle/ToggleIcon.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;AAC3B;AACA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,8BAA8B;EAC9B,+BAA+B;EAC/B,mBAAmB;EACnB,eAAe;AACjB;AACA;EACE,mBAAmB;EACnB,wCAAwC;AAC1C","sourcesContent":[".mask-box {\n  border-radius: 20px;\n  position: relative;\n  background: white;\n  margin-left: 15px;\n  border: 1px solid #D8D8D8;\n}\n.mask-box .mask {\n  width: 100px;\n  height: 40px;\n  border-radius: 20px;\n  background: #4C85F7;\n  position: absolute;\n  transition: all 0.2s ease;\n}\n.mask-box .MuiButton-root {\n  border-radius: 20px;\n  width: 100px;\n  height: 40px;\n  font-weight: bold;\n  transition: all 0.2s 0.1s ease;\n  text-transform: none !important;\n  white-space: nowrap;\n  font-size: 15px;\n}\n.mask-box .MuiButton-root:hover {\n  border-radius: 18px;\n  background-color: transparent !important; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
