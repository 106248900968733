// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logout-details {
  padding-left: 5%;
}
.logout-details__firstitem {
  margin-top: 10px;
}
.logout-details__seconditem {
  margin-top: 20px;
}
.logout-details__iconwrap {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/common/sideBarLogout/styles.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;AAFF;AAGE;EACE,gBAAA;AADJ;AAGE;EACE,gBAAA;AADJ;AAGE;ECJA,aAAA;EAGE,mBDEuB;AAA3B","sourcesContent":["@import \"src/scss/colors\";\n@import \"src/scss/variables\";\n\n.logout-details {\n  padding-left: 5%;\n  &__firstitem {\n    margin-top: 10px;\n  }\n  &__seconditem {\n    margin-top: 20px;\n  }\n  &__iconwrap {\n    @include flexContainer(center, null);\n  }\n}\n","// mixins\n@mixin widthHeight($width, $height) {\n  width: #{$width}px;\n  height: #{$height}px;\n}\n\n@mixin flexContainer($align-items: null, $justify-content: null) {\n  display: flex;\n\n  @if $align-items != null {\n    align-items: $align-items;\n  }\n\n  @if $justify-content != null {\n    justify-content: $justify-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
