import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import { ToastContainer } from "react-toastify";
import "./App.scss";

import { MyContext } from "./context/ContextApi";

import Document from "./components/documentQuery/Document";
import ExcistingDocument from "./components/documentQuery/ExcistingDocument";

import Zoom from "./components/zoom/Zoom";
import ZoomExisting from "./components/zoom/ZoomExisting";

import DataDetective from "./components/dataDetective/DataDetective";
import ExcistingDataDetective from "./components/dataDetective/ExcistingDataDective";

import ComDataDetective from "./components/comDataDetective/ComDataDetective";
import ComExcistingDataDetective from "./components/comDataDetective/ComExcistingDataDective";

import ListFiles from "./components/files/Files";
import DashboardQuery from "./components/dashboard/DashboardQuery";
import ExicistingDashboard from "./components/dashboard/ExicistingDashboard";

import TableauQuery from "./components/tableau/TableauQuery";
import ExicistingTableau from "./components/tableau/ExicistingTableau";

import ReadmeViewer from "./components/help/Help";
import PubmedQuery from "./components/pubmedSearch/Pubmed";
import GenInspector from "./components/genInspector/GenInspector";
import Admin from "./components/admin/Admin";
import Layout from "./layout/Layout";
import NewChat from "./components/Chat";
import ExicistingChat from "./components/Chat/Exicisting";

import DynamicChart from "./components/Chart/DynamicChart";
import ExicistingDynamicChart from "./components/Chart/ExicistingDynamicChart";

import ContentChange from "./components/ContentChange/ContentChange";
import ContentGeneration from "./components/ContentGeneration/contentGeneration";
import FileUpload from "./components/LanguageTranslation/fileUploading";

import MlrReview from "./components/MlrReview/mlrReview";
// import PersonalIdentification from "./components/MlrReview/personalIdentity";
// import PersonalIdentityInImage from "./components/MlrReview/personalIdentityImage";
// import WaterMarkDetection from "./components/MlrReview/waterMarkDetection";

import UploadCallinsights from "./components/callInsights/UploadCallinsights";
import CommonPromptFilePage from "./components/callInsights/CommonPromptFilePage";

import { MsalProvider } from "@azure/msal-react";

// let LoginPage = lazy(() => import("./components/login/loginpage"));
import LoginPage from "./components/login/loginpage";
import MedLingo from "./components/LanguageTranslation/medLingo";
import MedPulse from "./components/LanguageTranslation/medPulse";
import MedInsights from "./components/LanguageTranslation/medInsights";
import ProductExpiry from "./components/Product Expiry Mitigation/productExpiry";
import OmniLens from "./components/Omni lens/OmniLens";
import ExcistingOmnilens from "./components/Omni lens/ExistingOmnilens";
import MedInsightsSenti from "./components/Sentiment Analysis/medInsightsSenti";
import MedPulseSenti from "./components/Sentiment Analysis/medPulseSenti";
import SentimentNavbar from "./components/Sentiment Analysis/SentimentNavbar";
import EmergencySolution from "./components/EmergencySolution/EmergencySolution";
import DocumentGeneration from "./components/DocumentGeneration/documentGeneration";
import DocumentStructure from "./components/DocumentGeneration/documentStructure";
import DocumentHeading from "./components/DocumentGeneration/documentHeading";
import DocumentEditor from "./components/DocumentGeneration/documentEditor";
import DocumentFileExtract from "./components/DocumentGeneration/documentFileExtract";
// import SpeakerPro from "./components/speakerPro/speakerPro";


function App({ instance }) {
  const [loggedin, setLoggedin] = useState(false);
  const [allValues, setAllvalues] = useState();
  const [currentSession, setCurretSession] = useState("");
  const [filterContent, setFilterContent] = useState({
    source: [],
    relevance: "",
  });
  // const [post, setPost] = useState("");
  const [dashboardname, setDashboardname] = useState({
    name: "",
    region: "",
  });
  const [sessionUpdate, setSessionUpdate] = useState(false);
  const [newQuery, setNewQuery] = useState(false);
  const [collectionfetch, setCollectionfetch] = useState(false);
  const [userDropdDownValue, setUserDropDownValue] = useState({
    value: "OpenAI",
    label: "OpenAI",
  });
  const [dropDownPersona, setDropDownPersona] = useState();
  const [dropDownDashvistaData, setDropDownDashvistaData] = useState();
  const [personaValueList, setPersonaValueList] = useState();
  const [sessionPersonaVal, setSessionPersonaval] = useState();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isDiseaseListShow, setIsDiseaseListShow] = useState(false);

  const [commonLoading, setCommonLoading] = useState(false);
  const [isGeneTableList, setGeneTableList] = useState([]);
  const [isGeneTableClear, setGeneTableClear] = useState(false);
  const [isFileUploadStatus, setIsFileUploadStatus] = useState(false);
  const [isSubmitGeneIns, setIsSubmitGeneIns] = useState(false);
  const [isApiCallLength, setIsApiCallLength] = useState(0);
  const [isNavMenuVisible, setIsNavMenuVisibile] = useState(false);

  const [isMedPulseData, setIsMedPulseData] = useState({});

  const startLoading = () => setCommonLoading(true);
  const stopLoading = () => setCommonLoading(false);

  const [personaOptions, setPersonaOptions] = useState([]);
  const [isClickUploadDoc, setIsClickUpload] = useState(false);
  const [fileContentList, setFileContentList] = useState([]);

  //hcp mlt
  const [tableHeading, setTableHeading] = useState([]);
  const [isFilePath, setIsfilePath] = useState({});

  const [drugColumn, setDrugColumn] = useState([]);

  const [isFileStatusSuccess, setIsFileStatusSuccess] = useState();
  const [loaderEnable, setLoaderEnable] = useState(false);
  const [loaderEnableTwo, setLoaderEnableTwo] = useState(false);
  const [loaderEnableThree, setLoaderEnableThree] = useState(false);
  const [radioBtnOneEnable, setRadioBtnOneEnable] = useState(false);
  const [radioBtnTwoEnable, setRadioBtnTwoEnable] = useState(false);
  const [radioBtnThreeEnable, setRadioBtnThreeEnable] = useState(false);
  const [detectivePersonaVal, setDetectivePersoanVal] = useState();
  const [detectivePersonaTab, setDetectivePersonaTab] = useState();

  const [comPersonaVal, setComPersoanVal] = useState(null);
  const [comPersonaTab, setComPersonaTab] = useState(null);

  const [zoomCollValue, setZoomCollValue] = useState("");

  const [documents, setDocuments] = useState([]);
  const [selectedFileUrls, setSelectedFileUrls] = useState("");
  const [selectedFileType, setSelectedFileType] = useState("");
  const [selectedFile, setSelectedFile] = useState([]);

  const [orangeFlag, setOrangeFlag] = useState([]);
  const [redFlag, setRedFlag] = useState([]);

  const [claimsDataSrc, setClaimsDataSrc] = useState([]);
  const [currPageClaims, setCurrPageClaims] = useState();

  const [logoDataSrc, setLogoDataSrc] = useState([]);
  const [currPageLogo, setCurrPageLogo] = useState();

  //hcp mlt
  const [medLingo, setMedLingo] = useState(false);
  const [medPulse, setMedPulse] = useState(false);
  const [medInsights, setMedInsights] = useState(false);
  const [storedFileName, setStoredFileName] = useState();

  //sentiment analysis
  // const [sentiMedLingo, setSentiMedLingo] = useState(false);
  const [sentiMedPulse, setSentiMedPulse] = useState(false);
  const [sentiMedInsights, setSentiMedInsights] = useState(false);
  const [sentiStoredFileName, setSentiStoredFileName] = useState();
  const [sentiTableHeading, setSentiTableHeading] = useState([]);
  const [isFilePathSenti, setIsfilePathSenti] = useState({});
  const [sentiDrugColumn, setSentiDrugColumn] = useState([]);
  const [isSentiMedPulseData, setIsSentiMedPulseData] = useState({});
  const [sentiStoredFileNameMedpulse, setSentiStoredFileNameMedpulse] =
    useState();
  const [sentiDropDownMedpulse, setSentiDropDownMedpulse] = useState();
  const [value, setValue] = useState("1");

  const [summarizeClick, setSummarizeClick] = useState(false);

  //ContentGenaration

  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [structureArticle, setStructureArticle] = useState([]);
  const [structureHeading, setStructureHeading] = useState();
  const [contenttype, setContentType] = useState("");
  const [rawHtmlContent, setRawHtmlContent] = useState("");
  const [contentID, setContentID] = useState();
  const [selectedtemplate, setselectedtemplate] = useState("");
  const [retrivedInfo, setRetrivedInfo] = useState("");
  const [convertedMarkdown, setConvertedMarkdown] = useState("");
  const [extractedFields, setExtractedFields] = useState("");
  const [contentIDFileExtract, setContentIDFileExtract] = useState();
  const [contentHeading, setContentHeading] = useState("");
  const [method, setMethod] = useState("");
  const [backButton, setBackButton] = useState("");
  const [structurecontentID, setStructureContentID] = useState("");
  const [fileType, setFileType] = useState("");
  const [viewContentType, setViewContentType] = useState("");
  const [viewPage, setViewPage] = useState("");
  const [filePath, setFilePath] = useState("");
  const [journalPaperTitle, setJournalPaperTitle] = useState("");




  // DataLens
  const [plotJson, setPlotJson] = useState("");


  //faqModal
  const [faqModalShow, setFaqModalShow] = useState(false);

  // callinsights_2
  const [fileNameExtract, setFileNameExtract] = useState();
  const [queryResponse, setQueryResponse] = useState([]);
  const [query, setQuery] = useState([]);
  const [sourceCallIn, setSourceCallIn] = useState(false);
  const [sourceDataCallIn, setSourceDataCallIn] = useState("");
  const [fileName, setFileName] = useState();

  const [generating, setGenerating] = useState(false);

  const clearDocuments = () => setDocuments([]);

  useEffect(() => {
    const login = sessionStorage.getItem("email");
    if (login) {
      setLoggedin(true);
    }
  }, [loggedin]);

  return (
    <>
      <MsalProvider instance={instance}>
        <MyContext.Provider
          value={{
            isTableVisible,
            setIsTableVisible,
            isDiseaseListShow,
            setIsDiseaseListShow,
            allValues,
            setAllvalues,
            sessionUpdate,
            setSessionUpdate,
            dashboardname,
            setDashboardname,
            filterContent,
            setFilterContent,
            newQuery,
            setNewQuery,
            currentSession,
            setCurretSession,
            collectionfetch,
            setCollectionfetch,
            personaOptions,
            setPersonaOptions,
            userDropdDownValue,
            setUserDropDownValue,
            dropDownPersona,
            setDropDownPersona,
            setSessionPersonaval,
            sessionPersonaVal,
            dropDownDashvistaData,
            setDropDownDashvistaData,
            commonLoading,
            startLoading,
            stopLoading,
            personaValueList,
            setPersonaValueList,
            setGeneTableList,
            isGeneTableList,
            setGeneTableClear,
            isGeneTableClear,
            setIsFileUploadStatus,
            isFileUploadStatus,
            setIsSubmitGeneIns,
            isSubmitGeneIns,
            isApiCallLength,
            setIsApiCallLength,
            isClickUploadDoc,
            setIsClickUpload,
            isNavMenuVisible,
            setIsNavMenuVisibile,
            tableHeading,
            setTableHeading,
            drugColumn,
            setDrugColumn,
            isMedPulseData,
            setIsMedPulseData,
            isFilePath,
            setIsfilePath,
            isFileStatusSuccess,
            setIsFileStatusSuccess,
            loaderEnable,
            setLoaderEnable,
            loaderEnableTwo,
            setLoaderEnableTwo,
            loaderEnableThree,
            setLoaderEnableThree,
            radioBtnOneEnable,
            radioBtnTwoEnable,
            radioBtnThreeEnable,
            setRadioBtnOneEnable,
            setRadioBtnTwoEnable,
            setRadioBtnThreeEnable,
            fileContentList,
            setFileContentList,
            documents,
            setDocuments,
            selectedFileUrls,
            setSelectedFileUrls,
            selectedFileType,
            setSelectedFileType,
            clearDocuments,
            detectivePersonaVal,
            setDetectivePersoanVal,
            detectivePersonaTab,
            setDetectivePersonaTab,
            zoomCollValue,
            setZoomCollValue,
            comPersonaVal,
            setComPersoanVal,
            comPersonaTab,
            setComPersonaTab,
            orangeFlag,
            setOrangeFlag,
            redFlag,
            setRedFlag,
            claimsDataSrc,
            setClaimsDataSrc,
            currPageClaims,
            setCurrPageClaims,
            logoDataSrc,
            setLogoDataSrc,
            currPageLogo,
            setCurrPageLogo,
            selectedFile,
            setSelectedFile,
            medLingo,
            setMedLingo,
            medPulse,
            setMedPulse,
            medInsights,
            setMedInsights,
            storedFileName,
            setStoredFileName,
            summarizeClick,
            setSummarizeClick,
            // sentiMedLingo,
            // setSentiMedLingo,
            sentiMedPulse,
            setSentiMedPulse,
            sentiMedInsights,
            setSentiMedInsights,
            sentiStoredFileName,
            setSentiStoredFileName,
            sentiTableHeading,
            setSentiTableHeading,
            isFilePathSenti,
            setIsfilePathSenti,
            sentiDrugColumn,
            setSentiDrugColumn,
            isSentiMedPulseData,
            setIsSentiMedPulseData,
            sentiStoredFileNameMedpulse,
            setSentiStoredFileNameMedpulse,
            sentiDropDownMedpulse,
            setSentiDropDownMedpulse,
            faqModalShow,
            setFaqModalShow,
            value,
            setValue,
            fileNameExtract,
            setFileNameExtract,
            queryResponse,
            setQueryResponse,
            query,
            setQuery,
            sourceCallIn,
            setSourceCallIn,
            sourceDataCallIn,
            setSourceDataCallIn,
            fileName,
            setFileName,
            generating,
            setGenerating,
            isSideBarOpen,
            setIsSideBarOpen,
            structureArticle,
            setStructureArticle,
            structureHeading,
            setStructureHeading,
            contenttype,
            setContentType,
            rawHtmlContent,
            setRawHtmlContent,
            contentID,
            setContentID,
            selectedtemplate,
            setselectedtemplate,
            setRetrivedInfo,
            retrivedInfo,
            setConvertedMarkdown,
            convertedMarkdown,
            extractedFields,
            setExtractedFields,
            contentIDFileExtract,
            setContentIDFileExtract,
            contentHeading,
            setContentHeading,
            method,
            setMethod,
            backButton,
            setBackButton,
            structurecontentID,
            setStructureContentID,
            fileType,
            setFileType,
            viewContentType,
            setViewContentType,
            viewPage,
            setViewPage,
            filePath,
            setFilePath,
            plotJson,
            setPlotJson,
            journalPaperTitle,
            setJournalPaperTitle,
          }}
        >
          {/* <ReadmeViewer /> */}
          <Routes>
            <Route
              path="/"
              index
              element={<LoginPage setLoggedin={setLoggedin} />}
            />
            <Route
              path="/newquery/:id"
              element={
                <Layout>
                  <ExicistingChat />
                </Layout>
              }
            />
            <Route
              path="/documentquery/:id"
              element={
                <Layout>
                  <ExcistingDocument />
                </Layout>
              }
            />
            <Route
              path="/zoom"
              index
              element={
                <Layout>
                  <Zoom />
                </Layout>
              }
            />
            <Route
              path="/zoom/:id"
              element={
                <Layout>
                  <ZoomExisting />
                </Layout>
              }
            />
            <Route
              path="/dataDetective"
              index
              element={
                <Layout>
                  <DataDetective />
                </Layout>
              }
            />
            <Route
              path="/dataDetective/:id"
              element={
                <Layout>
                  <ExcistingDataDetective />
                </Layout>
              }
            />
            <Route
              path="/comdataDetective"
              index
              element={
                <Layout>
                  <ComDataDetective />
                </Layout>
              }
            />
            <Route
              path="/comdataDetective/:id"
              element={
                <Layout>
                  <ComExcistingDataDetective />
                </Layout>
              }
            />
            <Route
              path="/seeFiles"
              element={
                <Layout>
                  <ListFiles />
                </Layout>
              }
            />
            <Route
              path="/dashboardquery"
              element={
                <Layout>
                  <DashboardQuery />
                </Layout>
              }
            />
            <Route
              path="/dashboardquery/:id"
              element={
                <Layout>
                  <ExicistingDashboard />
                </Layout>
              }
            />
            <Route
              path="/tableauquery"
              element={
                <Layout>
                  <TableauQuery />
                </Layout>
              }
            />
            <Route
              path="/contentChange"
              element={
                <Layout>
                  <ContentChange />
                </Layout>
              }
            />
            <Route
              path="/tableauquery/:id"
              element={
                <Layout>
                  <ExicistingTableau />
                </Layout>
              }
            />
            <Route
              path="/newquery"
              element={
                loggedin ? (
                  <Layout>
                    <NewChat />
                  </Layout>
                ) : (
                  <LoginPage setLoggedin={setLoggedin} />
                )
              }
            />
            <Route
              path="/admin/persona"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/user"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/collection"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/schedule"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/admin/workBook"
              index
              element={
                <Layout>
                  <Admin />
                </Layout>
              }
            />
            <Route
              path="/documentquery"
              index
              element={
                <Layout>
                  <Document />
                </Layout>
              }
            />
            <Route
              path="/:id/help"
              index
              element={
                <Layout>
                  <ReadmeViewer />
                </Layout>
              }
            />
            <Route
              path="/pubmedquery"
              index
              element={
                <Layout>
                  <PubmedQuery />
                </Layout>
              }
            />
            <Route
              path="/textchartquery"
              index
              element={
                <Layout>
                  <DynamicChart />
                </Layout>
              }
            />
            <Route
              path="/textchartquery/:id"
              index
              element={
                <Layout>
                  <ExicistingDynamicChart />
                </Layout>
              }
            />
            <Route
              path="/genInspector"
              index
              element={
                <Layout>
                  <GenInspector />
                </Layout>
              }
            />
            <Route
              path="/contentGeneration"
              element={
                <Layout>
                  <ContentGeneration />
                </Layout>
              }
            />
            <Route
              path="/documentGeneration"
              element={
                <Layout>
                  <DocumentGeneration />
                </Layout>
              }
            />
            <Route
              path="/documentStructure"
              element={
                <Layout>
                  <DocumentStructure />
                </Layout>
              }
            />
            <Route
              path="/documentHeading"
              element={
                <Layout>
                  <DocumentHeading />
                </Layout>
              }
            />
            <Route
              path="/documentEditor"
              element={
                <Layout>
                  <DocumentEditor />
                </Layout>
              }
            />
            <Route
              path="/documentFileExtract"
              element={
                <Layout>
                  <DocumentFileExtract />
                </Layout>
              }
            />
            {/* <Route
              path="/speakerPro"
              element={
                <Layout>
                  <SpeakerPro />
                </Layout>
              }
            /> */}
            <Route
              path="/fileUploading"
              element={
                <Layout>
                  <FileUpload />
                </Layout>
              }
            />
            <Route
              path="/medLingo"
              element={
                <Layout>
                  <MedLingo />
                </Layout>
              }
            />
            <Route
              path="/medPulse"
              element={
                <Layout>
                  <MedPulse />
                </Layout>
              }
            />
            <Route
              path="/medInsights"
              element={
                <Layout>
                  <MedInsights />
                </Layout>
              }
            />
            <Route
              path="/mlrReview"
              element={
                <Layout>
                  <MlrReview />
                </Layout>
              }
            />
            <Route
              path="/productExpiry"
              element={
                <Layout>
                  <ProductExpiry />
                </Layout>
              }
            />
            <Route
              path="/omnilens"
              element={
                <Layout>
                  <OmniLens />
                </Layout>
              }
            />
            <Route
              path="/omnilens/:id"
              element={
                <Layout>
                  <ExcistingOmnilens />
                </Layout>
              }
            />
            <Route
              path="/sentimentAnalysis"
              element={
                <Layout>
                  <SentimentNavbar />
                </Layout>
              }
            />
            <Route
              path="/sentiMedPulse"
              element={
                <Layout>
                  <MedPulseSenti />
                </Layout>
              }
            />
            <Route
              path="/sentiMedInsights"
              element={
                <Layout>
                  <MedInsightsSenti />
                </Layout>
              }
            />
            <Route
              path="/salesRepCopilot"
              element={
                <Layout>
                  <EmergencySolution />
                </Layout>
              }
            />
            <Route
              path="/callInsights_2"
              element={
                <Layout>
                  <UploadCallinsights />
                </Layout>
              }
            />
            <Route
              path="/promptExtractFilesPage"
              element={
                <Layout>
                  <CommonPromptFilePage />
                </Layout>
              }
            />
          </Routes>
        </MyContext.Provider>
      </MsalProvider>

      <ToastContainer
        autoClose={1000}
        position="top-right"
        hideProgressBar
        theme="colored"
      />
      <Tooltip
        id="my-tooltip"
        style={{
          zIndex: "3",
          maxWidth: "50%",
          background: "black",
          fontSize: "0.85rem",
        }}
        place="bottom"
      />
      <Tooltip
        id="article"
        style={{
          zIndex: "3",
          maxWidth: "15%",
          background: "black",
          fontSize: "0.85rem",
        }}
        place="bottom"
      />
    </>
  );
}

export default App;
