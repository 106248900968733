// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Schedulesubmitbutton {
  background-color: #4c85f7;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 150px;
  margin-bottom: 30px;
  margin-top: 20px;
}

.Schedulesubmitbutton:hover {
  background-color: #4c85f7;
  color: white;
}

.card {
  width: 550px;
  margin: 0 auto;
  padding: 30px;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-content {
  display: flex;
  flex-direction: column;
}

.input-field-schedule {
  margin-bottom: 16px;
}

.label {
  margin-bottom: 8px;
  font-weight: bold;
}

input[type="date"],
input[type="time"],
select {
  width: 100%;
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-container-schedule {
  display: grid;
  grid-template-columns: 23% 77%;
  align-items: center;
  width: 100%;
  margin-bottom: 5%;
  gap: 2%;
}

.scheduleContent {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 78vh;
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/components/admin/schedule.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;;EAGE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;EACX,iBAAiB;EACjB,OAAO;AACT;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".Schedulesubmitbutton {\n  background-color: #4c85f7;\n  border: none;\n  color: white;\n  padding: 10px 30px;\n  text-align: center;\n  font-size: 20px;\n  font-weight: 500;\n  border-radius: 5px;\n  margin-left: 150px;\n  margin-bottom: 30px;\n  margin-top: 20px;\n}\n\n.Schedulesubmitbutton:hover {\n  background-color: #4c85f7;\n  color: white;\n}\n\n.card {\n  width: 550px;\n  margin: 0 auto;\n  padding: 30px;\n  background-color: whitesmoke;\n  border-radius: 10px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n\n.card-content {\n  display: flex;\n  flex-direction: column;\n}\n\n.input-field-schedule {\n  margin-bottom: 16px;\n}\n\n.label {\n  margin-bottom: 8px;\n  font-weight: bold;\n}\n\ninput[type=\"date\"],\ninput[type=\"time\"],\nselect {\n  width: 100%;\n  padding: 7px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.input-container-schedule {\n  display: grid;\n  grid-template-columns: 23% 77%;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 5%;\n  gap: 2%;\n}\n\n.scheduleContent {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 78vh;\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
