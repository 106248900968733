import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { GoSearch } from "react-icons/go";
import { IoMdClose } from "react-icons/io";
import { TbMaximize, TbArrowsMaximize } from "react-icons/tb";
import { documentContentUpload } from "../../api/Postaction";
import "./index.css";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";

const FileUpload = () => {
  const { setTableHeading, setIsfilePath, setMedLingo } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [selectedFile, setSelectedFile] = useState(null);
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [records, setRecords] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [expSearch, setExpSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableMaximize, setTableMaximize] = useState(false);

  const startIndex = limit * (page - 1);

  const handleBrowseFilesClick = () => {
    const fileInput = document.getElementById("fileInput");
    fileInput.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setSearchQuery("");
  };
  
  const toggleExpSearch = () => {
    setExpSearch((prevExpSearch) => !prevExpSearch);
  };

  useEffect(() => {
    if (selectedFile) {
      setIsLoading(true);
      const uploadFile = async () => {
        try {
          const result = await documentContentUpload(selectedFile);
          setData(result.df_data);
          setTableHeading(result.column_list);
          let fileData = {
            recent_file_path: result?.recent_file_path,
            file_name: result?.file_name,
          };
          setIsfilePath(fileData);
        } catch (error) {
          console.error("Error uploading file:", error);
        } finally {
          setIsLoading(false); // Set loading to false when API request ends
        }
      };

      uploadFile();
    }
  }, [selectedFile]);

  const handleClose = () => {
    setSelectedFile(null);
    setData([]);
    setRecords([]);
    setPage(1);
    setLimit(10);
    const fileInput = document.getElementById("fileInput");
    fileInput.value = null;
  };

  const filterData = () => {
    if (searchQuery.trim() === "") {
      return data;
    }
    return data.filter((item) => {
      return Object.values(item).some((val) =>
        val.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterData();
    const start = limit * (page - 1);
    const end = start + limit;
    const slicedData = filteredData.slice(start, end);
    setRecords(slicedData);
  }, [data, page, limit, searchQuery]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1); // Reset page when limit changes
  };

  // const handleMaximize = () => {
  //   setTableMaximize((prev) => !prev);
  // };

  if(records.length > 0 && selectedFile.name) {
    setMedLingo(true)
  }

  return (
    <div className="contentScroll">
      <div className="container">
        <div className="d-flex justify-content-center my-5">
          <div className="col-9">
            <div className="row">
              <div className="col-12">
                <h5 className="d-flex mt-3 mb-2">Upload your Document:</h5>
              </div>
              <div className="col-12">
                <p className="d-flex uploadText">
                  Upload your document in XLSX or CSV file
                </p>
                <div className="card contentCardInMlt">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-1">
                        <CloudUploadOutlinedIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                      <div className="col-9">
                        <h6 className="dragText">Drag and Drop file here</h6>
                        <p className="limitText">
                          Limit 200MB per file.XLSX,CSV
                        </p>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn-sm btn-light btnLight"
                          type="button"
                          onClick={handleBrowseFilesClick}
                        >
                          Browse files
                        </button>
                        <input
                          id="fileInput"
                          type="file"
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                          accept={".xlsx, .csv"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {selectedFile && (
                  <div>
                    <div className="col-12 fileName">
                      <div className="row">
                        <div className="col-1">
                          <InsertDriveFileOutlinedIcon color="primary" />
                        </div>
                        <div className="col-10">
                          <p>{selectedFile.name}</p>
                        </div>
                        <div className="col-1">
                          <CloseOutlinedIcon
                            className="iconHover"
                            fontSize="small"
                            onClick={handleClose}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLoading ? (
                  <CommonLoader /> // Show common loader when loading is true
                ) : (
                  records.length > 0 && (
                    <>
                      <div className="mb-1">
                        <div className="searchContainer">
                          <input
                            type="search"
                            className={expSearch && "exp-search-show"}
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearchChange}
                          />
                          <button
                            type="button"
                            className="btnSearch"
                            onClick={toggleExpSearch}
                          >
                            {expSearch ? <IoMdClose /> : <GoSearch />}
                          </button>
                        </div>
                      </div>
                      <Table
                        className="custom-table"
                        virtualized
                        wordWrap="break-word"
                        hover={false}
                        height={400}
                        data={records}
                        style={{
                          scrollbarWidth: "0",
                          maxHeight: "50vh",
                          border: "1px solid #4C85F7",
                        }}
                        align="center"
                        bordered
                        rowKey={(rowData) => rowData.id}
                      >
                        {/* <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>S.No</HeaderCell>
                          <Cell>
                            {(rowData, rowIndex) => startIndex + rowIndex + 1}
                          </Cell>
                        </Column>
                        <Column flexGrow={3} fullText verticalAlign="middle">
                          <HeaderCell>Hcp Comments</HeaderCell>
                          <Cell dataKey="Hcp Comments" />
                        </Column>
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>SKS Product</HeaderCell>
                          <Cell dataKey="SKS Product" />
                        </Column>
                        <Column flexGrow={1} verticalAlign="middle">
                          <HeaderCell>Country</HeaderCell>
                          <Cell dataKey="country" />
                        </Column> */}

                        {[...Object.keys(records[0])].map((key, index) => (
                          <Column
                            flexGrow={
                              key === "S.No"
                                ? 1
                                : key.toLowerCase().includes("comments")
                                ? 3
                                : 2
                            } // Customize flexGrow based on the key
                            verticalAlign="middle"
                            key={key}
                          >
                            <HeaderCell>{key}</HeaderCell>
                            <Cell dataKey={key}>
                              {(rowData, rowIndex) => {
                                // if (key === "S.No") {
                                //   return startIndex + rowIndex + 1; // Render serial number
                                // }
                                return rowData[key];
                              }}
                            </Cell>
                          </Column>
                        ))}
                      </Table>
                      <div style={{ paddingTop: 20 }}>
                        <Pagination
                          prev
                          next
                          first
                          last
                          ellipsis
                          boundaryLinks
                          maxButtons={5}
                          size="xs"
                          layout={["total", "-", "limit", "|", "pager", "skip"]}
                          total={data.length}
                          limitOptions={[10, 30, 50]}
                          limit={limit}
                          activePage={page}
                          onChangePage={handlePageChange}
                          onChangeLimit={handleLimitChange}
                        />
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;
