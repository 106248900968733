import React, { useState, useContext } from "react";
import "./index.css";
import { MyContext } from "../../context/ContextApi";

const SidebarNavFiles = ({ handleComponentChange }) => {
  const [selectedButton, setSelectedButton] = useState("/fileUploading");
  const { medLingo, medPulse, medInsights } = useContext(MyContext);

  const handleRadioButtonClick = (path) => {
    setSelectedButton(path);
    handleComponentChange(path);
  };

  const radioButtons = [
    {
      id: "radio1",
      name: "checkbox",
      path: "/fileUploading",
      label: "Upload",
      contextValue: true,
    },
    {
      id: "radio2",
      name: "checkbox",
      path: "/medLingo",
      label: "Med Lingo",
      contextValue: medLingo,
    },
    {
      id: "radio3",
      name: "checkbox",
      path: "/medPulse",
      label: "Med Pulse",
      contextValue: medPulse,
    },
    {
      id: "radio4",
      name: "checkbox",
      path: "/medInsights",
      label: "Med Insights",
      contextValue: medInsights,
    },
  ];

  return (
    <>
      <div>
        <h4 className="custom-margin mb-3">Med Hub</h4>
      </div>
      <div>
        {radioButtons.map(({ id, name, path, label, contextValue }) => (
          <div key={id} className="form-check sideLinkMenu">
            <input
              class="check"
              type="radio"
              id={id}
              name={name}
              value={path}
              checked={path === selectedButton}
              disabled={!contextValue}
              onChange={() => handleRadioButtonClick(path)}
            />
            <label class="form-check-label" for={id}>
              <div class="labelcontainer">
                <div class="labelcRadioBtn">
                  <div class="labeloverlay"></div>
                  <div class="drops xsDrop"></div>
                  <div class="drops mdDrop"></div>
                  <div class="drops lgDrop"></div>
                </div>
              </div>

              {label}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

export default SidebarNavFiles;
