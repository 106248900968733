import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import "./index.css";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { FaRegSave } from "react-icons/fa";
import { columnDataForMedPulseSenti } from "../../api/Postaction";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getDefaultSentimentDataAnalysis } from "../../api/GetActions";

function MedPulseSenti() {
  const {
    setSentiStoredFileNameMedpulse,
    setSentiMedInsights,
    sentiStoredFileName,
    setSentiDropDownMedpulse,
    sentiTableHeading,
  } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [recordsSenti, setRecordsSenti] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sentimentVal, setSentimentVal] = useState("");
  const [defaultSentiVal, setDefaultSentiVal] = useState();

  useEffect(() => {
    const sentimentData = async () => {
      try {
        let res = await getDefaultSentimentDataAnalysis();
        if (res) {
          setDefaultSentiVal(res);
        }
      } catch (err) {
        console.log(err);
      }
    };
    sentimentData();
  }, []);

  const tableData = defaultSentiVal
    ? Object.entries(defaultSentiVal).map(([sentiment, description]) => ({
        Sentiment: sentiment,
        Description: description,
      }))
    : [];

  const data = recordsSenti?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const handleLimitChange = (newLimit) => {
    setTimeout(() => {
      setLimit(newLimit);
      setPage(1);
    }, 100);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    let body = {
      stored_file_name: sentiStoredFileName,
      column_name: sentimentVal,
      sentiments: defaultSentiVal,
    };
    try {
      const res = await columnDataForMedPulseSenti(body);
      setRecordsSenti(res?.sent_df_data);
      setSentiStoredFileNameMedpulse(res.stored_sen_file);
      setSentiDropDownMedpulse(res.senti_col);
      setLoading(false);
      if (res && res?.sent_df_data) {
        setSentiMedInsights(true);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSelect = (event) => {
    setSentimentVal(event.target.value);
  };

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex flex-column align-items-start">
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <label className="midLingoLabel">
                      Med Pulse unveiling the emotional landscape of
                      pharmaceutical data through sentiment analysis
                    </label>
                    <div className="mt-3 col-12">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <FormControl
                            style={{ width: "18vw" }}
                            variant="standard"
                          >
                            <InputLabel id="demo-customized-select-label">
                              Choose Columns
                            </InputLabel>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={sentimentVal}
                              onChange={handleSelect}
                            >
                              {sentiTableHeading.map((option) => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <div
                            className="saveButton"
                            onClick={handleSaveClick}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "20px",
                            }}
                          >
                            <span>Analyze</span>
                            <FaRegSave
                              color="black"
                              size={20}
                              style={{
                                marginLeft: "5px",
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {tableData && tableData.length > 0 ? (
                        <div
                          style={{
                            marginTop: "30px",
                            maxHeight: "300px",
                            overflowX: "auto",
                          }}
                        >
                          <table
                            style={{
                              marginTop: "20px",
                              width: "100%",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead>
                              <tr>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "left",
                                  }}
                                >
                                  Sentiment
                                </th>
                                <th
                                  style={{
                                    border: "1px solid #ddd",
                                    padding: "8px",
                                    textAlign: "center",
                                  }}
                                >
                                  Description
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {tableData.map((rowData, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {rowData.Sentiment}
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: "8px",
                                    }}
                                  >
                                    {rowData.Description}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {/* <Table
                            data={tableData}
                            bordered
                            height={180}
                            rowKey={(rowData) => rowData.Sentiment}
                          >
                            <Column verticalAlign="middle">
                              <HeaderCell>Sentiment</HeaderCell>
                              <Cell dataKey="Sentiment" />
                            </Column>

                            <Column flexGrow={3} verticalAlign="middle">
                              <HeaderCell>Description</HeaderCell>
                              <Cell dataKey="Description">
                                {(rowData) => (
                                  <div
                                    style={{
                                      maxHeight: "60px",
                                      overflowY: "auto",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {rowData.Description}
                                  </div>
                                )}
                              </Cell>
                            </Column>
                          </Table> */}
                        </div>
                      ) : (
                        "No data"
                      )}
                      {loading ? (
                        <CommonLoader />
                      ) : recordsSenti?.length > 0 ? (
                        <>
                          <Table
                            className="custom-table-medPulse-senti"
                            virtualized
                            wordWrap="break-word"
                            hover={false}
                            height={400}
                            data={data}
                            style={{
                              scrollbarWidth: "0",
                              maxHeight: "50vh",
                              border: "1px solid #4C85F7",
                              marginTop: "40px",
                            }}
                            align="center"
                          >
                            {recordsSenti?.length > 0 &&
                              Object.keys(recordsSenti[0]).map((key, index) => (
                                <Column
                                  flexGrow={
                                    key === "S.No"
                                      ? 1
                                      : key.toLowerCase().includes("comments")
                                      ? 3
                                      : 2
                                  }
                                  verticalAlign="middle"
                                  key={key}
                                >
                                  <HeaderCell>{key}</HeaderCell>
                                  <Cell dataKey={key}>
                                    {(rowData, rowIndex) => rowData[key]}
                                  </Cell>
                                </Column>
                              ))}
                          </Table>
                          <div style={{ paddingTop: 20 }}>
                            <>
                              <Pagination
                                className="table-container-medPulse"
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                size="xs"
                                layout={[
                                  "total",
                                  "-",
                                  // "limit",
                                  // "|",
                                  "pager",
                                  "skip",
                                ]}
                                total={recordsSenti.length}
                                limitOptions={[10, 30, 50]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleLimitChange}
                              />
                            </>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MedPulseSenti;
