// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list {
  background-color: white;
  height: 88vh;
  overflow: scroll;
  border-radius: 10px;
  padding: 20px 20px 10px 20px;
}

.bucket {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1%;
}
.buckets {
  margin-top: 2%;
  height: 69vh;
  overflow: scroll;
  /* background-color: red; */
}

.bucketTitle
{
  color: #EA356F;
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/files/Files.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,4BAA4B;AAC9B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,OAAO;AACT;AACA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,2BAA2B;AAC7B;;AAEA;;EAEE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".list {\n  background-color: white;\n  height: 88vh;\n  overflow: scroll;\n  border-radius: 10px;\n  padding: 20px 20px 10px 20px;\n}\n\n.bucket {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  gap: 1%;\n}\n.buckets {\n  margin-top: 2%;\n  height: 69vh;\n  overflow: scroll;\n  /* background-color: red; */\n}\n\n.bucketTitle\n{\n  color: #EA356F;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
