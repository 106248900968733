import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MedInsightsSenti from "./medInsightsSenti";
import SentimentAnalysis from "./fileUploadingSentiment";
import MedPulseSenti from "./medPulseSenti";
import { MyContext } from "../../context/ContextApi";

function SentimentNavbar() {

  const { sentiMedPulse, sentiMedInsights, value, setValue } = useContext(MyContext);

  const handleChange = (event, newValue) => {
    if (
      (newValue === "2" && !sentiMedPulse) ||
      (newValue === "3" && !sentiMedInsights)
    ) {
      return;
    }
    setValue(newValue);
  };
  
  return (
    <div>
    <Box
      sx={{
        width: "92%",
        marginLeft: "4%",
        marginTop: "1%",
        typography: "body1",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab style={{ fontWeight: 600 }} label="Upload" value="1" />
            <Tab
              style={{
                fontWeight: 600,
                opacity: sentiMedPulse ? 1 : 0.5,
                pointerEvents: sentiMedPulse ? 'auto' : 'none',
              }}
              label="Med Pulse"
              value="2"
            />
            <Tab
              style={{
                fontWeight: 600,
                opacity: sentiMedInsights ? 1 : 0.5,
                pointerEvents: sentiMedInsights ? 'auto' : 'none',
              }}
              label="Med Insights"
              value="3"
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <SentimentAnalysis />
        </TabPanel>
        <TabPanel value="2">
          <MedPulseSenti />
        </TabPanel>
        <TabPanel value="3">
          <MedInsightsSenti />
        </TabPanel>
      </TabContext>
    </Box>
  </div>
  );
}

export default SentimentNavbar;
