import React, { useContext } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { MyContext } from "../../context/ContextApi";

function Faq() {
  const { setFaqModalShow } = useContext(MyContext);

  const handlFaqClicked = () => {
    setFaqModalShow(true);
  };

  return (
    <div style={{ display: "flex", gap: "10px", marginTop: '50px' }} onClick={handlFaqClicked}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FaQuestionCircle style={{ color: "white", fontSize: "24px" }} />
      </div>
      <span>FAQ</span>
    </div>
  );
}

export default Faq;
