// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.session-history {
  height: 33vh;
  overflow: scroll;
}
.session-history__history-list {
  display: flex;
  align-items: center;
  padding: 5px 0px;
  padding-left: 15px;
  border-radius: 5px;
}
.session-history__history-list:hover {
  background-color: rgba(242, 224, 189, 0.1490196078);
}
.session-history__history-list__icon {
  display: none;
  align-items: center;
  cursor: pointer;
}
.session-history__history-list:hover .session-history__history-list__icon {
  display: flex;
}
.session-history__input {
  font-size: 0.8rem;
  padding: 5px 0px;
  width: 100% !important;
  overflow: scroll;
  white-space: nowrap;
  cursor: pointer;
}
.session-history__input__border {
  border: none;
  padding: 2px 3px;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/common/sidebarSessionHistory/styles.scss","webpack://./src/scss/_variables.scss","webpack://./src/scss/_colors.scss"],"names":[],"mappings":"AAGA;EACE,YAAA;EACA,gBAAA;AAFF;AAIE;ECAA,aAAA;EAGE,mBDFuB;EACvB,gBAAA;EACA,kBAAA;EACA,kBAAA;AADJ;AAGI;EACE,mDEZO;AFWb;AAII;EACE,aAAA;EACA,mBAAA;EACA,eAAA;AAFN;AAKI;EACE,aAAA;AAHN;AAME;EACE,iBAAA;EACA,gBAAA;EACA,sBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAJJ;AAKI;EACE,YAAA;EACA,gBAAA;EACA,cErCE;AFkCR","sourcesContent":["@import \"src/scss/colors\";\n@import \"src/scss/variables\";\n\n.session-history {\n  height: 33vh;\n  overflow: scroll;\n\n  &__history-list {\n    @include flexContainer(center, null);\n    padding: 5px 0px;\n    padding-left: 15px;\n    border-radius: 5px;\n\n    &:hover {\n      background-color: $lightblack;\n    }\n\n    &__icon {\n      display: none;\n      align-items: center;\n      cursor: pointer;\n    }\n\n    &:hover &__icon {\n      display: flex;\n    }\n  }\n  &__input {\n    font-size: 0.8rem;\n    padding: 5px 0px;\n    width: 100% !important;\n    overflow: scroll;\n    white-space: nowrap;\n    cursor: pointer;\n    &__border {\n      border: none;\n      padding: 2px 3px;\n      color: $black;\n    }\n  }\n}\n","// mixins\n@mixin widthHeight($width, $height) {\n  width: #{$width}px;\n  height: #{$height}px;\n}\n\n@mixin flexContainer($align-items: null, $justify-content: null) {\n  display: flex;\n\n  @if $align-items != null {\n    align-items: $align-items;\n  }\n\n  @if $justify-content != null {\n    justify-content: $justify-content;\n  }\n}\n","$black: #000000;\n$blacshadow: #00000061;\n$lightblack: #f2e0bd26;\n$lightgreen: #888b8d;\n$gray_46: #464646;\n$gray7c: #7c7c7c;\n$graycc: #cccccc;\n$grayc1: #c1c1c1;\n$white: #ffffff;\n$grayf5: #f5f5f5;\n$bgwhite_f8: #f8f8f8;\n$bgwhite_f7: #f7f7f7;\n$primary: #4c85f7;\n$blue_b7: #035eb7;\n$btnred_6f: #ea346f;\n$orange65: #ffc965;\n$orangea8: #feffa8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
