// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.clear-btn {
  font-size: 0.75rem;
  border-bottom: 1px solid #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/common/commonClearBtn/styles.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gCAAA;AADF","sourcesContent":["@import \"src/scss/colors\";\n\n.clear-btn {\n  font-size: 0.75rem;\n  border-bottom: 1px solid $white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
