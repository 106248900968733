const dev = process.env.REACT_APP_API_DEV_URL; // Test

export const postUrl = {
  sendToken: `${dev}/sso/authentication`,
  createPersona:
    "https://dev-geninsights.agilisiumtech.com:452/data_lens/add_persona",
  createUser: `${dev}/login/create_user/`,
  login: `${dev}/login/user_login/`,
  //prompt: `${dev}/session/Prompt_chat/`,
  prompt: `https://dev-geninsights.agilisiumtech.com:452/data_lens/text_result`,
  sessionlist: `https://dev-geninsights.agilisiumtech.com:452/data_lens/get_sessions/`,
  sessionHistory: `https://dev-geninsights.agilisiumtech.com:452/data_lens/list_session_chat/`,
  audio: `https://dev-geninsights.agilisiumtech.com:452/Email/create_audio/`,
  sendEmail: `https://dev-geninsights.agilisiumtech.com:452/Email/send_email/`,
  shareWhatsApp: `${dev}/WhatsApp/send_message_text/`,
  kpi: `${dev}/kpi/summary/`,
  // uploadDocuments: `${dev}/document_search/upload_document/`,
  uploadDocuments: `https://dev-geninsights.agilisiumtech.com:448/document_search/upload_document`,

  documentNewsession: `${dev}/document_session/new_doc_search_session/`,
  // documentChathistory: `${dev}/document_session/Display_doc_search_session_chat/`,
  // documentPrompt: `${dev}/document_search/retrieve_from_document/`,
  documentPrompt: `https://dev-geninsights.agilisiumtech.com:448/document_search/retrieve_from_document`,
  documentChathistory: `https://dev-geninsights.agilisiumtech.com:448/document_session/display_doc_search_session_chat`,

  createCollection: `https://dev-geninsights.agilisiumtech.com:444/login/create_collection/`,
  localDocument: `https://dev-geninsights.agilisiumtech.com:448/document_search/local_doc_upload`,
  bucketList: `${dev}/document_search/bucket_list/`,
  dashboardPrompt: `${dev}/dashboard_search/dashboard_chat/`,
  summaryDashboard: `${dev}/dashboard_search/dashboard_summary/`,
  dashboardSessionHistory: `${dev}/dasboard_session/get_dashboard_search_sessions/`,
  dashboardChathistory: `${dev}/dasboard_session/Display_dashboard_session_chat/`,
  schedule: `${dev}/scheduler/create-rule/`,
  feedback: `https://dev-geninsights.agilisiumtech.com:452/data_lens/feedback-data/`,
  data_audio_history: `https://dev-geninsights.agilisiumtech.com:452/data_lens/data_audio_history/`,
  document_audio_history: `https://dev-geninsights.agilisiumtech.com:448/document_session/document_audio_history`,
  dashboard_audio_history: `${dev}/dasboard_session/dashboard_audio_history/`,
  pubmedSearch: `${dev}/pubmed/pubmed_search/`,
  referencePapers: `${dev}/pubmed/reference_papers/`,
  dataRenamesession: `https://dev-geninsights.agilisiumtech.com:452/data_lens/rename_data_lens_sessions/`,
  documentRenamesession: `${dev}/document_session/rename_doc_search_sessions/`,
  dashboardRenamesession: `${dev}/dasboard_session/rename_dashboard_search_sessions/`,
  dashRenameSession: `${dev}/text_to_dashboard/rename_dash_vista_chat_ses/`,
  decodedContent: `https://dev-geninsights.agilisiumtech.com:448/document_search/convert_path_to_encoded_content`,
  modalSwapLogs: `${dev}/login/model_swap_logs/`,
  upload_doc_pubmed: `${dev}/pubmed/upload_doc_pubmed/`,
  upload_local_document_pub: `${dev}/pubmed/upload_local_doc_pubmed/`,
  updating_disease_tagged: `${dev}/pubmed/updating_disease_tagged/`,
  textToDashboard: `${dev}/text_to_dashboard/txt_to_dash`,
  chartChatHistory: `${dev}/text_to_dashboard/display_dash_vista_history/`,
  chartSessionHistory: `${dev}/text_to_dashboard/get_dash_vista_sessions/`,
  pubmedSearchSummary: `${dev}/pubmed/pubmed_search_summary/`,
  audioPromptForData: `${dev}/session/Prompt_chat_speech/`,
  audioPromptForDoc: `${dev}/document_search/retrieve_from_document_speech/`,
  audioPromptForDash: `${dev}/dashboard_search/dashboard_chat_speech/`,
  new_chat_session: `${dev}/pubmed/new_chat_session/`,
  uri_endpoint: `${dev}/session/uri_endpoint/`,
  search_get_data_json: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/get_data_json/`,
  tableau_dashboard_summary: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_summary/`,
  tableau_dashboard_chat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat/`,
  tableau_dashboard_chat_speech: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat_speech/`,
  tableauRenameSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/rename_dashboard_search_sessions/`,
  tableauSessionChat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Display_dashboard_session_chat/`,
  tableauAudioHistory: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/dashboard_audio_history/`,
  createWorkBook: `${dev}/login/create_workbook_kpi/`,
  fetchGeneData: `${dev}/gene_inspector/fetch_gene_data/`,
  uploadDocGen: `${dev}/gene_inspector/upload_local_doc_gene/`,
  addChartResponseInHistory: `${dev}/text_to_dashboard/adding_dash_vista_history/`,
  uploadDocumetGt: `${dev}/content_tagging/upload_document_ct/`,
  uploadLocalDocumetGt: `${dev}/content_tagging/upload_local_document_ct/`,
  contentTaggingSearch: `${dev}/content_tagging/content_tagging_search/`,
  contentDbList: `${dev}/content_tagging/list_databases/`,
  contentTableList: `${dev}/content_tagging/list_tables/`,
  contentTagTable: `${dev}/content_tagging/insert_tags_into_table/`,
  // contentTemplateUpload: `${dev}/content_generator/upload-template/`,
  contentTemplateUpload: `https://dev-geninsights.agilisiumtech.com:555/content_generator/upload-template/`,
  // contentGenerateInfo: `${dev}/content_generator/extract-info/`,
  contentGenerateInfo: `https://dev-geninsights.agilisiumtech.com:555/content_generator/upload-info-doc/`,
  contentFileLists: `https://dev-geninsights.agilisiumtech.com:555/content_generator/list-reference-file/`,
  contentGenerateReference: `https://dev-geninsights.agilisiumtech.com:555/content_generator/extract-info-reference/`,
  contentRagaScore: `https://dev-geninsights.agilisiumtech.com:555/content_generator/ragas-score/`,
  // contentGenerateDocument: `${dev}/content_generator/generate-document/`,
  contentGenerateDocument: `https://dev-geninsights.agilisiumtech.com:555/content_generator/generate-document/`,
  dropCollection: `https://dev-geninsights.agilisiumtech.com:555/content_generator/drop-collection/`,
  translateDocumentUpload: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/upload/`,
  translateDocumentUploadSenti: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/upload/`,
  translateColumnData: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/Med_Lingo/`,
  translateColumnDataSenti: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/Med_Lingo/`,
  uniqueValueForColumnData: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  uniqueValueForColumnDataSenti: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  dataResForMedInsight: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/Med_Insights/`,
  dataResForMedInsightSenti: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/Med_Insights/`,
  getColumnDataForMedPulse: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/Med_Pulse/`,
  getColumnDataForMedPulseSenti: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/Med_Pulse/`,
  uploadMlrDoc: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/upload_pdf`,
  personalIdentityData: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/process_pii_and_watermarks`,
  claimsTagging: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/claims_tagging`,
  uploadLogo: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/upload_logo_image`,
  //logoMatch: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/logo_not_matched`,
  //waterMarkDetectionData: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-watermarks/`,
  //personalIdentityWaterMark: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-pii-watermark-in-images/`,
  deleteCollectionListFile: `https://dev-geninsights.agilisiumtech.com:555/content_generator/delete-ref-file-s3/`,
  deleteCollectionAllListFile: `https://dev-geninsights.agilisiumtech.com:555/content_generator/delete-all-file-s3/`,

  dataDetectivePrompt: `https://dev-geninsights.agilisiumtech.com:449/gen_data/detective_chat`,
  datadecRenamesession: `https://dev-geninsights.agilisiumtech.com:449/session/rename_sessions/`,
  dataDetectiveSession: `https://dev-geninsights.agilisiumtech.com:449/session/get_sessions/`,
  dataDetectiveChatHistory: `https://dev-geninsights.agilisiumtech.com:449/session/list_session_chat/`,

  // commercial data detective
  comDetectivePrompt: `https://dev-geninsights.agilisiumtech.com:453/gen_data/detective_chat`,
  comDetectiveSession: `https://dev-geninsights.agilisiumtech.com:453/session/get_sessions/`,
  comDetetectiveRename: `https://dev-geninsights.agilisiumtech.com:453/session/rename_sessions/`,
  comDetectiveChatHistory: `https://dev-geninsights.agilisiumtech.com:453/session/list_session_chat/`,

  getEvaluate: `https://dev-geninsights.agilisiumtech.com:448/document_search/get_evaluate_response`,
  getEvaluateTableau: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/get-evaluate_response/`,

  getEvaluateDataLens: `https://dev-geninsights.agilisiumtech.com:452/data_lens/evaluate-response/`,

  //zoom api
  zoomRetreivePrompt: `https://dev-geninsights.agilisiumtech.com:450/document_search/retrieve_from_transcribed_document`,
  zoomSessionHistory: `https://dev-geninsights.agilisiumtech.com:450/document_session/get_doc_search_sessions`,
  zoomRenamesession: `https://dev-geninsights.agilisiumtech.com:450/document_session/rename_doc_search_sessions`,
  zoomAudioHistroy: `https://dev-geninsights.agilisiumtech.com:450/document_session/document_audio_history`,
  zoomAudioConvert: `https://dev-geninsights.agilisiumtech.com:450}/Email/create_audio`,
  zoomVideoConvert: `https://dev-geninsights.agilisiumtech.com:450/document_search/upload_local_video`,

  zoomChathistory: `https://dev-geninsights.agilisiumtech.com:450/document_session/display_doc_search_session_chat`,
  zoomDecodeContent: `https://dev-geninsights.agilisiumtech.com:450/document_search/convert_path_to_encoded_content`,

  documentSessionHistory: `https://dev-geninsights.agilisiumtech.com:448/document_session/get_doc_search_sessions`,
  tableauGetSessions: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/get_dashboard_search_sessions/`,

  summarizeDocSonar: `https://dev-geninsights.agilisiumtech.com:448/document_search/summary_test`,
  getDatalensDropdownList: `https://dev-geninsights.agilisiumtech.com:452/data_lens/get_dl_persona/`,

  getDropDownMedInsights: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/MedInsights/column_values/Column_and_unique_values_all/`,

  getAnswerSalesRepCopilot: `https://dev-geninsights.agilisiumtech.com:461/document_search/new_project`,

  getChatBotResponse: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/retrieve_from_dataset`,
  dropdownMedInsightsUniqueValue: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/MedInsights/column_values/Filtered_Column_and_unique_values/`,
};

export const getUrl = {
  tables: `${dev}/login/list_tables/`,
  persona: `${dev}/login/list_persona/`,
  users: `${dev}/login/list_user/`,
  tableAccess: `${dev}/login/get-persona-data/`,
  initialSetup: `${dev}/document_search/initial_setup/`,
  listCollection: `${dev}/login/list_collection/`,
  dashboardRegions: `${dev}/dashboard_search/list_regions/`,
  listDashboards: `${dev}/dashboard_search/list_dashboards/`,
  personauser: `${dev}/login/get_persona_tagged_users/`,
  getPersonaList: `${dev}/login/get-persona-list/`,
  getModelType: `${dev}/login/get_model_type/`,
  get_disease_tagged: `${dev}/pubmed/get_disease_tagged/`,
  get_all_disease_tagged: `${dev}/pubmed/get_all_disease_tagged`,
  tableau_list_project: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_project/`,
  tableau_list_workbook: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_workbook/`,
  getWorkBookKpi: `${dev}/login/list_workbookkpi/`,
  getWorkBookTable: `${dev}/login/list_workbookkpi_table/`,
  geneInsGeData: `${dev}/gene_inspector/get_data/`,
  downloadDocument: `https://dev-geninsights.agilisiumtech.com:555/content_generator/download_docx/`,
  getDefaultSentiments: `https://dev-geninsights.agilisiumtech.com:454/lang_trans_analytics/default_sentiments/`,
  getDefaultSentimentsAnalysis: `https://dev-geninsights.agilisiumtech.com:557/lang_trans_analytics/default_sentiments/`,
};

export const putUrl = {
  putPersona: `${dev}/login/edit_persona/`,
  putUser: `${dev}/login/edit_user/`,
  editCollection: `${dev}/login/edit_collection/`,
  editWorkBook: `${dev}/login/edit_workbook_kpi/`,
  editPersona: `https://dev-geninsights.agilisiumtech.com:452/data_lens/edit_persona/`,
};

export const deleteUrl = {
  deletePersona: `${dev}/login/delete_persona/`,
  deleteUser: `${dev}/login/delete_user/`,
  deleteSession: `https://dev-geninsights.agilisiumtech.com:452/data_lens/clear_sessions/`,
  deleteAllSession: `https://dev-geninsights.agilisiumtech.com:452/data_lens/delete_entire_session/`,
  deletedocument: `${dev}/document_session/clear_doc_search_sessions/`,
  deleteAlldocument: `${dev}/document_session/Delete_entire_doc_search_session/`,
  deleteCollection: `https://dev-geninsights.agilisiumtech.com:448/login/delete_collection`,
  deletedashboardsession: `${dev}/dasboard_session/clear_dashboard_search_sessions/`,
  deleteAlldashboardsession: `${dev}/dasboard_session/Delete_entire_dashboard_search_session/`,
  delete_file_s3_pub: `${dev}/pubmed/delete_file_s3_pub/`,
  delete_disease_tagged_db: `${dev}/pubmed/delete_disease_tagged_db/`,
  deleteTableauSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/clear_dashboard_search_sessions/`,
  deleteTableauSessionAll: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Delete_entire_dashboard_search_session/`,
  deleteAllSessioninDash: `${dev}/text_to_dashboard/Delete_entire_dash_vista_session/`,
  deleteSessionIdinDash: `${dev}/text_to_dashboard/clear_dash_vista_sessions/`,
  deleteWorkBookKpi: `${dev}/login/delete_workbook_kpi/`,
  deleteSingleFile: `https://dev-geninsights.agilisiumtech.com:448/document_search/delete_single_file/`,
  deleteCollectionCt: `${dev}/content_tagging/delete_collection_ct/`,

  dataDetecSessionId: `https://dev-geninsights.agilisiumtech.com:449/session/clear_sessions/`,
  dataDetecSessionAll: `https://dev-geninsights.agilisiumtech.com:449/session/delete_entire_session/`,

  comDetecSessionId: `https://dev-geninsights.agilisiumtech.com:453/session/clear_sessions/`,
  comDetecSessionAll: `https://dev-geninsights.agilisiumtech.com:453/session/delete_entire_session/`,

  ZoomDetecSessionId: `https://dev-geninsights.agilisiumtech.com:450/document_session/clear_doc_search_sessions`,
  ZoomDetecSessionAll: `https://dev-geninsights.agilisiumtech.com:450/document_session/Delete_entire_doc_search_session`,
  deleteOnePersona: `https://dev-geninsights.agilisiumtech.com:452/data_lens/delete_persona/`,
};
