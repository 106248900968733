import React, { useState, useEffect, useContext } from "react";
import { Table, Pagination } from "rsuite";
import "./index.css";
import { toast } from "react-toastify";
import { CommonLoader } from "../../common";
import { MyContext } from "../../context/ContextApi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdAddCircleOutline } from "react-icons/io";
import TextField from "@mui/material/TextField";
import { FaRegSave } from "react-icons/fa";
import { columnDataForMedPulse } from "../../api/Postaction";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { getDefaultSentimentData } from "../../api/GetActions";

const MedPulse = () => {
  const {
    setDrugColumn,
    // isMedPulseData,
    setMedInsights,
    storedFileName,
    setIsMedPulseData,
  } = useContext(MyContext);
  const { Column, HeaderCell, Cell } = Table;
  const [columnData, setColumnData] = useState([]);
  const [records, setRecords] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState([
    {
      sentiment_value: "",
      description: "",
    },
  ]);
  const [bodyData, setBodyData] = useState([{}]);
  const [sentimentVal, setSentimentVal] = useState("");
  const [defaultSentiVal, setDefaultSentiVal] = useState();
  // const [tableData, setTableData] = useState();

  useEffect(() => {
    const transformedObject = inputData.reduce((acc, curr) => {
      if (curr.sentiment_value && curr.description) {
        acc[curr.sentiment_value] = curr.description;
      }
      return acc;
    }, {});
    setBodyData(transformedObject);
  }, [inputData]);

  const filterData = () => {
    if (searchQuery.trim() === "") {
      return columnData;
    }
    return columnData?.filter((item) => {
      return Object.values(item).some((val) =>
        val.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterData();
    const start = limit * (page - 1);
    const end = start + limit;
    const slicedData = filteredData?.slice(start, end);
    setRecords(slicedData);
  }, [columnData, page, limit, searchQuery]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  if (records?.length > 0) {
    setMedInsights(true);
  }

  const handleAddClick = () => {
    if (sentimentVal === "Define Your Own Sentiments") {
      setInputData([...inputData, { sentiment_value: "", description: "" }]);
    }
  };

  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeValue = [...inputData];
    onChangeValue[i][name] = value;
    setInputData(onChangeValue);
  };

  const handleDelete = (i) => {
    const deleteValue = [...inputData];
    deleteValue.splice(i, 1);
    setInputData(deleteValue);
  };
  const handleSaveClick = async () => {
    setLoading(true);
    let body = {
      stored_file_name: storedFileName,
      sentiments: bodyData,
    };
    try {
      if (
        sentimentVal === "Define Your Own Sentiments" &&
        Object.keys(bodyData).length > 0 &&
        storedFileName
      ) {
        const data = await columnDataForMedPulse(body);
        setRecords(data.sent_df_data);
        setDrugColumn(data.senti_col);
        if (data) {
          setIsMedPulseData(data);
        }
      } else if (sentimentVal === "Choose The Default Sentiments") {
        const default_sentiment = await getDefaultSentimentData();
        setDefaultSentiVal(default_sentiment);
        let body = {
          stored_file_name: storedFileName,
          sentiments: default_sentiment,
        };
        const data = await columnDataForMedPulse(body);
        setRecords(data.sent_df_data);
        setDrugColumn(data.senti_col);
        if (data) {
          setIsMedPulseData(data);
        }
      } else {
        toast.error("Failed to retrieve columns");
      }
    } catch (err) {
      console.log(err);
      toast.error("Failed to retrieve columns", err);
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (event) => {
    setSentimentVal(event.target.value);
  };

  const tableData = defaultSentiVal
    ? Object.keys(defaultSentiVal)?.map((key) => ({
        Sentiment: key,
        Description: defaultSentiVal[key],
      }))
    : [];

  return (
    <>
      <div className="contentScroll">
        <div className="container">
          <div className="d-flex justify-content-center my-5">
            <div className="col-9">
              <div className="row">
                <div className="col-12">
                  <h5 className="d-flex mt-3 mb-2">Med Pulse</h5>
                  <div className="row">
                    <label className="midLingoLabel">
                      Med Pulse unveiling the emotional landscape of
                      pharmaceutical data through sentiment analysis
                    </label>
                    <div className="mt-3 col-12">
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {sentimentVal === "Define Your Own Sentiments" ? (
                            <div
                              className="addButton"
                              onClick={handleAddClick}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span>Add</span>
                              <IoMdAddCircleOutline
                                color="black"
                                size={20}
                                style={{
                                  marginLeft: "8px",
                                  fontWeight: "bold",
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <FormControl
                            style={{ width: "18vw" }}
                            variant="standard"
                          >
                            <InputLabel id="demo-customized-select-label">
                              Choose Sentiments
                            </InputLabel>
                            <Select
                              labelId="demo-customized-select-label"
                              id="demo-customized-select"
                              value={sentimentVal}
                              onChange={handleSelect}
                              input={""}
                            >
                              <MenuItem value={"Choose The Default Sentiments"}>
                                Choose The Default Sentiments
                              </MenuItem>
                              <MenuItem value={"Define Your Own Sentiments"}>
                                Define Your Own Sentiments
                              </MenuItem>
                            </Select>
                          </FormControl>
                          {sentimentVal && (
                            <div
                              className="saveButton"
                              onClick={handleSaveClick}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>Analyze</span>
                              <FaRegSave
                                color="black"
                                size={20}
                                style={{
                                  marginLeft: "5px",
                                  fontWeight: "bold",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        {sentimentVal === "Define Your Own Sentiments" &&
                          inputData.map((inputData, i) => {
                            return (
                              <div
                                className="col-12"
                                style={{
                                  display: "flex",
                                  marginTop: "20px",
                                  alignItems: "center",
                                }}
                              >
                                <TextField
                                  name="sentiment_value"
                                  value={inputData.sentiment_value}
                                  onChange={(e) => handleChange(e, i)}
                                  className="col-4"
                                  size="sm"
                                  id="outlined-basic"
                                  label="Sentiment"
                                  variant="outlined"
                                />
                                <TextField
                                  size="lg"
                                  style={{ marginLeft: "7px" }}
                                  className="col-7"
                                  name="description"
                                  id="outlined-basic"
                                  variant="outlined"
                                  value={inputData.description}
                                  label="Description"
                                  onChange={(e) => handleChange(e, i)}
                                />
                                <RiDeleteBin6Line
                                  className="col-1"
                                  onClick={() => handleDelete(i)}
                                  color="red"
                                  size={19}
                                  cursor="pointer"
                                />
                              </div>
                            );
                          })}
                      </div>
                      {sentimentVal === "Choose The Default Sentiments" &&
                      tableData?.length !== 0 ? (
                        <>
                          <Table
                            data={tableData}
                            className="custom-table"
                            virtualized
                            wordWrap="break-word"
                            hover={false}
                            height={400}
                            style={{
                              scrollbarWidth: "0",
                              maxHeight: "50vh",
                              border: "1px solid #4C85F7",
                              marginTop: "20px",
                            }}
                          >
                            <Column width={400} align="center" fixed>
                              <HeaderCell>Sentiment</HeaderCell>
                              <Cell dataKey="Sentiment" />
                            </Column>
                            <Column width={400} align="center">
                              <HeaderCell>Description</HeaderCell>
                              <Cell dataKey="Description" />
                            </Column>
                          </Table>
                        </>
                      ) : null}
                      {loading ? (
                        <CommonLoader />
                      ) : records?.length > 0 ? (
                        <>
                          <Table
                            className="custom-table"
                            virtualized
                            wordWrap="break-word"
                            hover={false}
                            height={400}
                            data={records}
                            style={{
                              scrollbarWidth: "0",
                              maxHeight: "50vh",
                              border: "1px solid #4C85F7",
                              marginTop: "20px",
                            }}
                            align="center"
                          >
                            {[...Object.keys(records[0])].map((key, index) => (
                              <Column
                                flexGrow={
                                  key === "S.No"
                                    ? 1
                                    : key.toLowerCase().includes("comments")
                                    ? 3
                                    : 2
                                }
                                verticalAlign="middle"
                                key={key}
                              >
                                <HeaderCell>{key}</HeaderCell>
                                <Cell dataKey={key}>
                                  {(rowData, rowIndex) => {
                                    return rowData[key];
                                  }}
                                </Cell>
                              </Column>
                            ))}
                          </Table>
                          <div style={{ paddingTop: 20 }}>
                            <Pagination
                              prev
                              next
                              first
                              last
                              ellipsis
                              boundaryLinks
                              maxButtons={5}
                              size="xs"
                              layout={[
                                "total",
                                "-",
                                "limit",
                                "|",
                                "pager",
                                "skip",
                              ]}
                              total={columnData.length}
                              limitOptions={[10, 30, 50]}
                              limit={limit}
                              activePage={page}
                              onChangePage={handlePageChange}
                              onChangeLimit={handleLimitChange}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MedPulse;
