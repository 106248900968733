// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.source-filter {
  padding: 2px 15px;
  font-size: 0.9rem;
  width: 100%;
  margin-top: 15%;
}
.source-filter__labelwrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.source-filter__inputlabel {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.source-filter__inputlabel__label {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/common/sideBarPubmedFilter/styles.scss","webpack://./src/scss/_variables.scss"],"names":[],"mappings":"AAGA;EACE,iBAAA;EACA,iBAAA;EACA,WAAA;EACA,eAAA;AAFF;AAIE;ECFA,aAAA;EAGE,mBAAA;EAIA,8BDJ+B;AAAnC;AAGE;ECNA,aAAA;EAGE,mBDIuB;EACvB,mBAAA;AAAJ;AACI;EACE,gBAAA;AACN","sourcesContent":["@import \"src/scss/colors\";\n@import \"src/scss/variables\";\n\n.source-filter {\n  padding: 2px 15px;\n  font-size: 0.9rem;\n  width: 100%;\n  margin-top: 15%;\n\n  &__labelwrap {\n    @include flexContainer(center, space-between);\n  }\n\n  &__inputlabel {\n    @include flexContainer(center, null);\n    margin-bottom: 15px;\n    &__label {\n      margin-left: 5px;\n    }\n  }\n}\n","// mixins\n@mixin widthHeight($width, $height) {\n  width: #{$width}px;\n  height: #{$height}px;\n}\n\n@mixin flexContainer($align-items: null, $justify-content: null) {\n  display: flex;\n\n  @if $align-items != null {\n    align-items: $align-items;\n  }\n\n  @if $justify-content != null {\n    justify-content: $justify-content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
