// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


.diseaseContainer
{
    margin: 20px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
   
}
.diseaseDropContainer
{
    width: 300px;
    margin-left: 10px;
    cursor: pointer;
}
.diseaseTitle
{
    font-size: 16px;
    font-weight: 400;
}
.diseaseDropWrap
{
    display: flex;
    align-items:center;
}

.diseaseGoBack
{
    display: flex;
    align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/components/files/DiseaseName.css"],"names":[],"mappings":";;;AAGA;;IAEI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;;AAEvB;AACA;;IAEI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;AACA;;IAEI,eAAe;IACf,gBAAgB;AACpB;AACA;;IAEI,aAAa;IACb,kBAAkB;AACtB;;AAEA;;IAEI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n\n\n.diseaseContainer\n{\n    margin: 20px 0px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n   \n}\n.diseaseDropContainer\n{\n    width: 300px;\n    margin-left: 10px;\n    cursor: pointer;\n}\n.diseaseTitle\n{\n    font-size: 16px;\n    font-weight: 400;\n}\n.diseaseDropWrap\n{\n    display: flex;\n    align-items:center;\n}\n\n.diseaseGoBack\n{\n    display: flex;\n    align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
