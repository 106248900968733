//import axios from "axios";

import axiosApi from "../interceptors/axiosinterceptor";
import { getUrl } from "./Endpoint";

export const userList = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.users}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in get user list", err);
  }
};

export const tableauLstProject = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.tableau_list_project}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const tableau_list_workbook = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.tableau_list_workbook}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getWorkBookApi = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.getWorkBookKpi}${id}/`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getWorkBookList = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.getWorkBookTable}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getGeneData = async (id) => {
  try {
    let res = await axiosApi.get(`${getUrl.geneInsGeData}${id}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error in InitialSetup", err);
  }
};

export const getDefaultSentimentData = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.getDefaultSentiments}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error getting data", err);
  }
};

export const getDefaultSentimentDataAnalysis = async () => {
  try {
    let res = await axiosApi.get(`${getUrl.getDefaultSentimentsAnalysis}`);
    if (res && res?.data) {
      return res.data;
    }
  } catch (err) {
    console.log("Error getting data", err);
  }
};

//download file
export const downloadFileContent = async (userName) => {
  try {
    const response = await axiosApi.get(
      `${getUrl.downloadDocument}?collection_name=${userName}`,
      {
        responseType: "blob",
      }
    );
    const url = URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "generated.docx");
    document.body.appendChild(link);
    link.click();
    document.body.appendChild(link);
  } catch (error) {
    console.error("Error in download file:", error);
  }
};
