import React, { useContext } from "react";
import { getChatBotResponseData } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { sentiStoredFileNameMedpulse } = useContext(MyContext);
  const handleHello = () => {
    const botMessage = createChatBotMessage("Hello. Nice to meet you.");

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const getAnswerFromAPI = async (question) => {
    let body = {
      stored_file_name_sen_tagged: sentiStoredFileNameMedpulse,
      user_query: question,
    };
    try {
      const response = await getChatBotResponseData(body);
      return response;
    } catch (error) {
      return "Sorry, I couldn't fetch an answer at the moment.";
    }
  };

  const handleApiResponse = async (question) => {
    const answer = await getAnswerFromAPI(question);
    const botMessage = createChatBotMessage(`${answer}`);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleHello,
            handleApiResponse,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
