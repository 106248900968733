// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btns {
  background: #ea346f;
  font-size: 1rem !important;
  border: none;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 400;
  padding: 7px 15px;
  outline: none !important;
}
.btns:hover {
  cursor: pointer;
  background: linear-gradient(to right, #035eb7, #4c85f7);
}`, "",{"version":3,"sources":["webpack://./src/common/commonBtn/styles.scss","webpack://./src/scss/_colors.scss"],"names":[],"mappings":"AAEA;EACE,mBCWU;EDVV,0BAAA;EACA,YAAA;EACA,kBAAA;EACA,cCCM;EDAN,gBAAA;EACA,iBAAA;EACA,wBAAA;AADF;AAEE;EACE,eAAA;EACA,uDAAA;AAAJ","sourcesContent":["@import \"src/scss/colors\";\n\n.btns {\n  background: $btnred_6f;\n  font-size: 1rem !important;\n  border: none;\n  border-radius: 6px;\n  color: $white;\n  font-weight: 400;\n  padding: 7px 15px;\n  outline: none !important;\n  &:hover {\n    cursor: pointer;\n    background: linear-gradient(to right, $blue_b7, $primary);\n  }\n}\n","$black: #000000;\n$blacshadow: #00000061;\n$lightblack: #f2e0bd26;\n$lightgreen: #888b8d;\n$gray_46: #464646;\n$gray7c: #7c7c7c;\n$graycc: #cccccc;\n$grayc1: #c1c1c1;\n$white: #ffffff;\n$grayf5: #f5f5f5;\n$bgwhite_f8: #f8f8f8;\n$bgwhite_f7: #f7f7f7;\n$primary: #4c85f7;\n$blue_b7: #035eb7;\n$btnred_6f: #ea346f;\n$orange65: #ffc965;\n$orangea8: #feffa8;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
