export const staticData = [
  "Give me an overview of Dr. John Smith.",
  "Provide details on the past interactions with Dr. John Smith and suggest next engagement steps.",
  "What are Dr. John Smith prescribing patterns over the last 6 months?",
  "Give me Dr. John Smith's Profile Exploration Clinical Trials.",
  "Can you provide me relevant content for Dr. John Smith?",
  "Can you generate an email that I can send to Dr John Smith?",
  "Can you provide details on recent clinical studies for the Product?",
  "Which digital channels have been most effective for Dr. John Smith?",
  "What is the next best action for Dr. Smith based on recent engagement?",
  "How many HCPs are in each stage of the customer adoption journey for Q1 2024?",
  "What is the engagement trend for HCPs across different journey stages?",
  "How many HCPs have progressed from Evaluation to Trialist stage in the last quarter? ",
  "Which channels have the highest engagement score this month?",
  "What is the channel affinity score for each segment of HCPs?",
  "Which channels are most effective in the Evaluation and Trialist stages? ",
  "Which channels are giving the best ROI based on media spend?",
  "How did the recent campaign perform across all channels?",
  "What is the click-through rate (CTR) across digital channels for the latest campaign?",
  "What is the engagement score trend over the past three months?",
  "How has the prescription trend changed month-over-month? ",
  "How does HCP engagement vary by region (e.g., East Coast vs. West Coast)?",
  "Which regions have shown the highest conversion rates from Trialist to Loyalist? ",
  "What strategic recommendations do you have for improving HCP retention?",
];
