// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.desc-tab__tables {
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 14px;
  text-align: left;
}

.table-sticky {
  width: 100%;
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto;
}

.desc-tab__tables__rowheading th {
  background-color: #f2f2f2;
  border-bottom: 1px solid #dddddd;
  padding: 12px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.desc-tab__tables__rowborder th {
  border-bottom: 2px solid #dddddd;
  padding: 12px;
}

.desc-tab__tables__databorder td {
  border-bottom: 1px solid #dddddd;
  padding: 12px;
}

.desc-tab__tables tr:hover {
  background-color: #f1f1f1;
}

.desc-tab__tables__databorder {
  padding: 12px;
}

.desc-tab__tables th,
.desc-tab__tables td {
  padding: 12px;
}`, "",{"version":3,"sources":["webpack://./src/common/commonPromtChat/styles.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,cAAA;EACA,eAAA;EACA,gBAAA;AACF;;AACA;EACE,WAAA;EACA,iBAAA,EAAA,4BAAA;EACA,gBAAA;AAEF;;AACA;EACE,yBAAA;EACA,gCAAA;EACA,aAAA;EACA,gBAAA;EACA,MAAA;EACA,UAAA;AAEF;;AACA;EACE,gCAAA;EACA,aAAA;AAEF;;AACA;EACE,gCAAA;EACA,aAAA;AAEF;;AACA;EACE,yBAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;;EAEE,aAAA;AAEF","sourcesContent":[".desc-tab__tables {\n  border-collapse: collapse;\n  margin: 20px 0;\n  font-size: 14px;\n  text-align: left;\n}\n.table-sticky {\n  width: 100%;\n  max-height: 400px; /* Adjust height as needed */\n  overflow-y: auto;\n}\n\n.desc-tab__tables__rowheading th {\n  background-color: #f2f2f2;\n  border-bottom: 1px solid #dddddd;\n  padding: 12px;\n  position: sticky;\n  top: 0;\n  z-index: 1;\n}\n\n.desc-tab__tables__rowborder th {\n  border-bottom: 2px solid #dddddd;\n  padding: 12px;\n}\n\n.desc-tab__tables__databorder td {\n  border-bottom: 1px solid #dddddd;\n  padding: 12px;\n}\n\n.desc-tab__tables tr:hover {\n  background-color: #f1f1f1;\n}\n\n.desc-tab__tables__databorder {\n  padding: 12px;\n}\n\n.desc-tab__tables th,\n.desc-tab__tables td {\n  padding: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
